<template>
  <div class="tab-wrapper">
    <el-radio-group
        v-model="type"
        style="margin-bottom: 24px;"
        @change="handleTypeChange"
      >
      <el-radio-button v-for="item in menus" :key="item.routerName" :label="item.routerName">{{item.text}}</el-radio-button>
    </el-radio-group>
    <!-- <div class="tab-menus">
      <ul>
        <li v-for="item in menus" :key="item.routerName" :class="{active:$route.name === item.routerName }"  @click="$router.push({name: item.routerName})" >
          {{item.text}}
        </li>
      </ul>
    </div> -->
    <div class="tab-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    const menus = [
      {
        routerName: 'distribution',
        text: '同城配送'
      },
      {
        routerName: 'expressage',
        text: '快递物流'
      },
      {
        routerName: 'takeTheir',
        text: '上门自提'
      }
    ]
    return {
      menus,
      type: 'distribution'
    }
  },
  methods: {
    handleTypeChange () {
      this.$router.push({ name: this.type })
    }
  }
}
</script>

<style lang="scss" scoped>
  .tab-wrapper {
    min-height: 300px;
    // padding-left: 100px;
    position: relative;

    & > .tab-menus {
      width: 100px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-right: 1px solid ; @include set_border_color($--border-color, $--border-color-dark);

      li {
        line-height: 28px;
        margin-bottom: 20px;
        color: $font-color-shallow9;
        cursor: pointer;
        margin-right: -1px;
        &.active {
          color: $-theme-color;
          border-right: 1px solid $-theme-color;
        }
      }
    }

    & > .tab-content {
      // padding-left: 50px;
    }
  }
</style>
